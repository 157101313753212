<template>
  <div>
    <div v-if="clientWidth > 960" class="pc">
      <form action="/">
        <van-search
          v-model="svalue"
          placeholder="Please enter your search keyword"
          @search="onSearch"
        />
      </form>
      <van-swipe class="goods-swipe" :autoplay="3000">
        <van-swipe-item v-for="thumb in goods.thumb" :key="thumb">
          <img :src="thumb" />
        </van-swipe-item>
      </van-swipe>
      <van-row justify="center">
        <van-col span="2"> <h2 class="block__title">Home</h2></van-col>
        <van-col span="7">
          <h2 class="block__title">whatapp/wechat/skype+8618950157679</h2>
        </van-col>
        <van-col span="15">
          <van-button
            v-for="item in cate"
            :key="item.id"
            :icon="item.image"
            type="primary"
            hairline
            class="menu_btn"
            @click="gotoCate(item.id)"
          >
            {{ item.title }}
          </van-button>
        </van-col>
      </van-row>
      <van-row justify="center" class="chosen">
        <van-col
          span="6"
          v-for="item in chosen"
          :key="item.id"
          class="r_item"
          @click="goto(item.id)"
        >
          <div class="img-div"><img :src="item.image" /></div>
          <div class="title tl">{{ item.title }}</div>
        </van-col>
      </van-row>
      <van-row justify="center">
        <van-col span="6">
          <h2 class="block__title">About XGMA</h2>
          <van-cell title="About" is-link to="/about-index" />
          <van-cell title="Contact" is-link to="/about-contact" />
        </van-col>
        <van-col span="6">
          <h2 class="block__title">Cooperative Partner</h2>
          <van-cell title="网址1跳转" is-link url="https://baidu.com" />
          <van-cell title="网址跳转" is-link url="https://baidu.com" />
          <van-cell title="网址跳转" is-link url="https://baidu.com" />
        </van-col>
        <van-col span="6">
          <h2 class="block__title">Cooperative Partner</h2>
          <van-cell title="网址跳转" is-link url="https://baidu.com" />
          <van-cell title="网址跳转" is-link url="https://baidu.com" />
          <van-cell title="网址跳转" is-link url="https://baidu.com" />
        </van-col>
        <van-col span="6">
          <h2 class="block__title">Contact Us</h2>
          <van-cell title="Contact Us" is-link to="about-contact" />
        </van-col>
      </van-row>
    </div>
    <div v-else class="wap">
      <form action="/">
        <van-search
          v-model="svalue"
          placeholder="Please enter your search keyword"
          @search="onSearch"
        />
      </form>
      <van-swipe class="goods-swipe" :autoplay="3000">
        <van-swipe-item v-for="thumb in thumbs" :key="thumb">
          <img :src="thumb" />
        </van-swipe-item>
      </van-swipe>

      <van-row justify="center">
        <van-col
          span="6"
          v-for="item in cate"
          :key="item.id"
          class="r_item"
          @click.capture="gotoCate(item.id)"
        >
          <van-icon class-prefix="my-icon" :name="item.image" size="40" />
          <div>{{ item.title }}</div>
        </van-col>
      </van-row>

      <van-row justify="center" class="chosen">
        <van-col
          span="12"
          v-for="item in chosen"
          :key="item.id"
          class="r_item"
          @click="goto(item.id)"
        >
          <div class="img-div"><img :src="item.image" /></div>
          <div class="title tl">{{ item.title }}</div>
        </van-col>
      </van-row>

      <siteabbar :idx="0" />
      <div class="lang-menu" @click="show = true">
        <i class="van-badge__wrapper van-icon van-icon-more-o lang-menu__icon">
          <!----><!----><!---->
        </i>
      </div>
      <van-action-sheet
        v-model:show="show"
        :actions="actions"
        cancel-text="取消"
        close-on-click-action
        @select="onSelect"
        @cancel="onCancel"
      />
    </div>
  </div>
</template>

<script>
import Siteabbar from "../components/Sitetabbar";
import { ref } from "vue";
import { mapState } from "vuex";
import { getIndexData } from "@/api/query";
import { Row, Col, Image } from "vant";
import { ActionSheet } from "vant";
import { useRouter } from "vue-router";

export default {
  components: {
    [Siteabbar.name]: Siteabbar,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [ActionSheet.name]: ActionSheet,
  },
  setup() {
    const activeIndex = ref(0);
    const router = useRouter();
    let show = ref(false);
    let svalue = ref("");
    const actions = [
      { name: "Language" },
      { name: "Language" },
      { name: "Language" },
    ];
    const onSelect = (item) => {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      show.value = false;
      console.log(item.name);
    };
    const onCancel = () => {
      show.value = false;
    };
    const onSearch = (val) => {
      router.push("/bar-search?k=" + val);
      console.log(val);
    };
    const onCancelSearch = () => console.log("取消");
    return {
      activeIndex,
      items: [{ text: "分组 1" }, { text: "分组 2" }],
      show,
      svalue,
      actions,
      thumbs: ["http://xgma.store/img/wap.png"],
      onSearch,
      onCancelSearch,
      onSelect,
      onCancel,
    };
  },
  computed: {
    ...mapState("cart", ["cart"]),
  },

  data() {
    return {
      cate: [],
      chosen: [],
      clientWidth: 0,
    };
  },
  created() {
    // Mock: get all routes and roles list from server
    this.windowWidth(document.documentElement.clientWidth);
    this.getIndexData();
  },

  methods: {
    windowWidth(value) {
      this.clientWidth = value;
    },
    async getIndexData() {
      const res = await getIndexData();
      this.cate = res.data.cate;
      this.chosen = res.data.chosen;
    },

    gotoCate(id) {
      this.$router.push("/bar-clist?catid=" + id);
    },

    goto(link) {
      this.$router.push("/goods?id=" + link);
    },
    goRouter(e) {
      // console.log(e);
      this.$router.push(e);
    },
  },
  mounted() {
    const _this = this;
    // var p = navigator.platform;
    // console.log(navigator);
    // alert(p);
    window.onresize = () => {
      return (() => {
        _this.clientWidth = `${document.documentElement.clientWidth}`;
      })();
    };
  },
  watch: {
    clientWidth(val) {
      if (!this.timer) {
        this.clientWidth = val;
        this.timer = true;
        let _this = this;
        setTimeout(function () {
          _this.timer = false;
        }, 500);
      }
      // 这里可以添加修改时的方法
      this.windowWidth(val);
    },
  },
};
</script>

<style lang="less" scoped>
.r_item {
  text-align: center;
  margin: 10px 0;
}
.title {
  font-size: 18px;
  line-height: 30px;
}
.top .r_item,
.chosen .r_item {
  padding: 0 10px;
}
.img-div {
  overflow: hidden;
}
.chosen .img-div {
  height: auto;
}
.img-div img {
  width: 100%;
  height: auto;
}
.wap {
  padding-bottom: 50px;

  .lang-menu {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    right: 30px;
    bottom: 100px;
    z-index: 100;
    cursor: pointer;
    color: #fff;
    border-radius: 999px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
    background-color: #1989fa;
  }

  .lang-menu:active {
    opacity: var(--van-active-opacity);
  }

  .lang-menu__placeholder {
    display: none;
  }

  .lang-menu__icon {
    font-size: 20px;
    font-weight: 600;
  }
}
.pc {
  .block__title {
    margin: 0;
    padding: 12px 16px 16px;
    color: #969799;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .menu_btn {
    margin: 5px 3px;
    padding: 0 10px;
    background-color: #ddd;
    color: #000;
  }
  .menu_btn:after {
    border: none;
  }
}

.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}
</style>
